import moment from 'moment'
// 轨迹数据,作业开始时间与作业结束时间
const generateTrackData = (data, workStartTime, workEndTime) => {
  const startTime = moment().format('YYYY-MM-DD') + ' ' + workStartTime
  const startDateTime = moment(startTime)._d.getTime()
  const endTime = moment().format('YYYY-MM-DD') + ' ' + workEndTime
  const endDateTime = moment(endTime)._d.getTime()
  // 先按轨迹点数均分时间段
  const splitTime = data.length > 0 ? Math.floor((endDateTime - startDateTime) / data.length) : 0
  let k = startDateTime
  data.forEach(p => {
    const gpsTime = Math.floor(Math.random() * splitTime + k)
    if (p) {
      // gps时间
      p.gpsTime = moment(gpsTime).format('YYYY-MM-DD HH:mm:ss')
      // 作业速度3-6
      p.speed = Math.floor(Math.random() * 30 + 30) / 10
    }
    k = k + splitTime
  })
  return data
}
// 上午：解放中路 2.7km
const oneTrackData = [
  { lng: 80.300469, lat: 41.177037 },
  { lng: 80.300685, lat: 41.176834 },
  { lng: 80.30082, lat: 41.176738 },
  { lng: 80.300963, lat: 41.176603 },
  { lng: 80.301125, lat: 41.176467 },
  { lng: 80.301332, lat: 41.176297 },
  { lng: 80.301484, lat: 41.176189 },
  { lng: 80.30161, lat: 41.176067 },
  { lng: 80.301754, lat: 41.175944 },
  { lng: 80.301862, lat: 41.175822 },
  { lng: 80.302068, lat: 41.175666 },
  { lng: 80.302293, lat: 41.175496 },
  { lng: 80.30241, lat: 41.175381 },
  { lng: 80.302562, lat: 41.175259 },
  { lng: 80.302679, lat: 41.175137 },
  { lng: 80.302814, lat: 41.175048 },
  { lng: 80.30294, lat: 41.174919 },
  { lng: 80.303048, lat: 41.174858 },
  { lng: 80.303137, lat: 41.174757 },
  { lng: 80.303299, lat: 41.174621 },
  { lng: 80.303407, lat: 41.174512 },
  { lng: 80.303578, lat: 41.17437 },
  { lng: 80.303676, lat: 41.174302 },
  { lng: 80.303766, lat: 41.174241 },
  { lng: 80.303793, lat: 41.174193 },
  { lng: 80.303856, lat: 41.174112 },
  { lng: 80.304009, lat: 41.173983 },
  { lng: 80.304099, lat: 41.173888 },
  { lng: 80.304251, lat: 41.173766 },
  { lng: 80.30444, lat: 41.173609 },
  { lng: 80.304575, lat: 41.173501 },
  { lng: 80.304745, lat: 41.173372 },
  { lng: 80.304907, lat: 41.173236 },
  { lng: 80.304979, lat: 41.173161 },
  { lng: 80.304835, lat: 41.17327 },
  { lng: 80.304745, lat: 41.173372 },
  { lng: 80.304575, lat: 41.173514 },
  { lng: 80.30444, lat: 41.17365 },
  { lng: 80.304305, lat: 41.173718 },
  { lng: 80.304197, lat: 41.17384 },
  { lng: 80.304117, lat: 41.173894 },
  { lng: 80.303928, lat: 41.174064 },
  { lng: 80.30373, lat: 41.174247 },
  { lng: 80.303506, lat: 41.174437 },
  { lng: 80.303389, lat: 41.174505 },
  { lng: 80.303245, lat: 41.174641 },
  { lng: 80.303119, lat: 41.174777 },
  { lng: 80.303021, lat: 41.174838 },
  { lng: 80.302913, lat: 41.174906 },
  { lng: 80.302679, lat: 41.175143 },
  { lng: 80.302518, lat: 41.175306 },
  { lng: 80.302338, lat: 41.175442 },
  { lng: 80.302113, lat: 41.175605 },
  { lng: 80.302068, lat: 41.17568 },
  { lng: 80.30188, lat: 41.175836 },
  { lng: 80.3017, lat: 41.175965 },
  { lng: 80.301493, lat: 41.176162 },
  { lng: 80.301332, lat: 41.176297 },
  { lng: 80.301161, lat: 41.176419 },
  { lng: 80.301035, lat: 41.176521 },
  { lng: 80.300919, lat: 41.176623 },
  { lng: 80.300748, lat: 41.176752 },
  { lng: 80.300541, lat: 41.176956 },
  { lng: 80.300362, lat: 41.177132 },
  { lng: 80.30029, lat: 41.177166 },
  { lng: 80.300155, lat: 41.177288 },
  { lng: 80.300011, lat: 41.177383 },
  { lng: 80.299903, lat: 41.177485 },
  { lng: 80.299769, lat: 41.177573 },
  { lng: 80.299652, lat: 41.177682 },
  { lng: 80.299472, lat: 41.177804 },
  { lng: 80.299364, lat: 41.17792 },
  { lng: 80.299293, lat: 41.178008 },
  { lng: 80.299221, lat: 41.178055 },
  { lng: 80.299068, lat: 41.178157 },
  { lng: 80.298915, lat: 41.178245 },
  { lng: 80.298817, lat: 41.178354 },
  { lng: 80.298691, lat: 41.178469 },
  { lng: 80.298565, lat: 41.178605 },
  { lng: 80.298475, lat: 41.178666 },
  { lng: 80.298736, lat: 41.17887 },
  { lng: 80.298906, lat: 41.178985 },
  { lng: 80.299113, lat: 41.179107 },
  { lng: 80.299167, lat: 41.179229 },
  { lng: 80.299293, lat: 41.179291 },
  { lng: 80.299436, lat: 41.179372 },
  { lng: 80.29958, lat: 41.179487 },
  { lng: 80.299715, lat: 41.179582 },
  { lng: 80.299957, lat: 41.179786 },
  { lng: 80.300182, lat: 41.179935 },
  { lng: 80.300362, lat: 41.180085 },
  { lng: 80.300559, lat: 41.180227 },
  { lng: 80.300748, lat: 41.180343 },
  { lng: 80.300954, lat: 41.180492 },
  { lng: 80.301161, lat: 41.180662 },
  { lng: 80.301332, lat: 41.180804 },
  { lng: 80.301476, lat: 41.180913 },
  { lng: 80.301709, lat: 41.181109 },
  { lng: 80.302023, lat: 41.181347 },
  { lng: 80.302248, lat: 41.181503 },
  { lng: 80.302419, lat: 41.181659 },
  { lng: 80.302589, lat: 41.181734 },
  { lng: 80.302724, lat: 41.181849 },
  { lng: 80.302877, lat: 41.181965 },
  { lng: 80.303083, lat: 41.182107 },
  { lng: 80.303308, lat: 41.182277 },
  { lng: 80.30347, lat: 41.182399 },
  { lng: 80.303622, lat: 41.182535 },
  { lng: 80.303811, lat: 41.182637 },
  { lng: 80.303703, lat: 41.182942 },
  { lng: 80.303524, lat: 41.183037 },
  { lng: 80.303308, lat: 41.183207 },
  { lng: 80.303164, lat: 41.183356 },
  { lng: 80.302958, lat: 41.183512 },
  { lng: 80.302778, lat: 41.183661 },
  { lng: 80.30258, lat: 41.183838 },
  { lng: 80.302419, lat: 41.183906 },
  { lng: 80.302284, lat: 41.184035 },
  { lng: 80.302113, lat: 41.184191 },
  { lng: 80.301943, lat: 41.184313 },
  { lng: 80.301763, lat: 41.184428 },
  { lng: 80.301556, lat: 41.184632 },
  { lng: 80.301341, lat: 41.184795 },
  { lng: 80.301035, lat: 41.185039 },
  { lng: 80.30073, lat: 41.185263 },
  { lng: 80.30046, lat: 41.185467 },
  { lng: 80.300227, lat: 41.185636 },
  { lng: 80.299859, lat: 41.185935 },
  { lng: 80.299589, lat: 41.186145 },
  { lng: 80.29932, lat: 41.186356 },
  { lng: 80.299113, lat: 41.186586 },
  { lng: 80.298772, lat: 41.186763 },
  { lng: 80.298619, lat: 41.186905 },
  { lng: 80.298385, lat: 41.187061 },
  { lng: 80.298242, lat: 41.187224 },
  { lng: 80.29808, lat: 41.187401 },
  { lng: 80.297667, lat: 41.187699 },
  { lng: 80.297469, lat: 41.187842 },
  { lng: 80.297379, lat: 41.187971 },
  { lng: 80.297182, lat: 41.188134 },
  { lng: 80.297029, lat: 41.188283 },
  { lng: 80.296831, lat: 41.188385 },
  { lng: 80.296625, lat: 41.188561 },
  { lng: 80.29649, lat: 41.18867 },
  { lng: 80.296247, lat: 41.188867 },
  { lng: 80.296068, lat: 41.189063 },
  { lng: 80.295834, lat: 41.189247 },
  { lng: 80.295726, lat: 41.189308 },
  { lng: 80.295556, lat: 41.189437 },
  { lng: 80.295331, lat: 41.189647 },
  { lng: 80.295133, lat: 41.189783 },
  { lng: 80.294945, lat: 41.189918 },
  { lng: 80.294729, lat: 41.190108 },
  { lng: 80.29446, lat: 41.190346 },
  { lng: 80.294235, lat: 41.190516 },
  { lng: 80.293984, lat: 41.190658 },
  { lng: 80.293786, lat: 41.190862 },
  { lng: 80.293561, lat: 41.191025 },
  { lng: 80.293328, lat: 41.191201 },
  { lng: 80.293031, lat: 41.191405 },
  { lng: 80.292762, lat: 41.191608 },
  { lng: 80.2926, lat: 41.19173 },
  { lng: 80.292349, lat: 41.191615 },
  { lng: 80.29216, lat: 41.191506 },
  { lng: 80.291953, lat: 41.191391 },
  { lng: 80.291747, lat: 41.191174 },
  { lng: 80.291397, lat: 41.190997 },
  { lng: 80.291244, lat: 41.190889 },
  { lng: 80.291001, lat: 41.19074 },
  { lng: 80.290741, lat: 41.190577 },
  { lng: 80.290435, lat: 41.190407 },
  { lng: 80.290175, lat: 41.190339 },
  { lng: 80.289923, lat: 41.190265 },
  { lng: 80.289546, lat: 41.190027 },
  { lng: 80.289259, lat: 41.189891 },
  { lng: 80.288926, lat: 41.189796 },
  { lng: 80.288576, lat: 41.1896 },
  { lng: 80.288001, lat: 41.189355 },
  { lng: 80.287749, lat: 41.189267 },
  { lng: 80.287453, lat: 41.189097 },
  { lng: 80.287148, lat: 41.189029 },
  { lng: 80.286896, lat: 41.188907 },
  { lng: 80.286671, lat: 41.188819 },
  { lng: 80.286195, lat: 41.188595 },
  { lng: 80.286258, lat: 41.188446 },
  { lng: 80.286492, lat: 41.188283 },
  { lng: 80.286689, lat: 41.188113 },
  { lng: 80.286923, lat: 41.187937 },
  { lng: 80.287067, lat: 41.187794 },
  { lng: 80.287255, lat: 41.187632 },
  { lng: 80.287426, lat: 41.18753 },
  { lng: 80.287615, lat: 41.187387 },
  { lng: 80.287767, lat: 41.187251 },
  { lng: 80.287938, lat: 41.187123 },
  { lng: 80.288127, lat: 41.186973 },
  { lng: 80.288252, lat: 41.186865 },
  { lng: 80.288441, lat: 41.186702 },
  { lng: 80.288612, lat: 41.186552 },
  { lng: 80.288827, lat: 41.186376 },
  { lng: 80.288989, lat: 41.186206 },
  { lng: 80.289286, lat: 41.18601 },
  { lng: 80.28942, lat: 41.185874 },
  { lng: 80.289573, lat: 41.185738 },
  { lng: 80.289726, lat: 41.185596 },
  { lng: 80.289941, lat: 41.18546 },
  { lng: 80.290148, lat: 41.185229 },
  { lng: 80.290552, lat: 41.184992 },
  { lng: 80.290786, lat: 41.184788 },
  { lng: 80.291145, lat: 41.184571 },
  { lng: 80.291361, lat: 41.18436 },
  { lng: 80.291549, lat: 41.184238 },
  { lng: 80.291801, lat: 41.184055 },
  { lng: 80.292025, lat: 41.183851 },
  { lng: 80.292223, lat: 41.183709 },
  { lng: 80.292448, lat: 41.183566 },
  { lng: 80.292618, lat: 41.18341 },
  { lng: 80.292816, lat: 41.183268 },
  { lng: 80.293013, lat: 41.183112 },
  { lng: 80.293247, lat: 41.182894 },
  { lng: 80.293508, lat: 41.182691 },
  { lng: 80.293723, lat: 41.182494 },
  { lng: 80.293912, lat: 41.182338 },
  { lng: 80.294154, lat: 41.182148 },
  { lng: 80.294433, lat: 41.181917 },
  { lng: 80.294729, lat: 41.181686 },
  { lng: 80.295008, lat: 41.181483 },
  { lng: 80.295187, lat: 41.1813 },
  { lng: 80.295403, lat: 41.181164 },
  { lng: 80.295654, lat: 41.180933 },
  { lng: 80.296068, lat: 41.180587 },
  { lng: 80.296247, lat: 41.180478 },
  { lng: 80.296454, lat: 41.180288 },
  { lng: 80.296741, lat: 41.180078 },
  { lng: 80.296939, lat: 41.179929 },
  { lng: 80.297173, lat: 41.179711 },
  { lng: 80.29737, lat: 41.179535 },
  { lng: 80.297658, lat: 41.179372 },
  { lng: 80.297864, lat: 41.179175 },
  { lng: 80.298071, lat: 41.179012 },
  { lng: 80.298251, lat: 41.17887 },
  { lng: 80.298358, lat: 41.17872 },
  { lng: 80.298754, lat: 41.178401 },
  { lng: 80.298915, lat: 41.178279 },
  { lng: 80.299131, lat: 41.178116 },
  { lng: 80.299364, lat: 41.177926 },
  { lng: 80.299589, lat: 41.177777 },
  { lng: 80.299796, lat: 41.177567 },
  { lng: 80.299948, lat: 41.177438 },
  { lng: 80.300065, lat: 41.177322 },
  { lng: 80.300299, lat: 41.177186 },
  { lng: 80.300416, lat: 41.177071 },
  { lng: 80.300442, lat: 41.176983 }
]
// 下午：民主路 5.3km
const twoTrackData = [
  { lng: 80.292734, lat: 41.146652 },
  { lng: 80.292581, lat: 41.146604 },
  { lng: 80.292312, lat: 41.14657 },
  { lng: 80.292204, lat: 41.146489 },
  { lng: 80.292043, lat: 41.146448 },
  { lng: 80.291899, lat: 41.146407 },
  { lng: 80.291773, lat: 41.146346 },
  { lng: 80.291548, lat: 41.146339 },
  { lng: 80.291387, lat: 41.146251 },
  { lng: 80.291216, lat: 41.146217 },
  { lng: 80.290929, lat: 41.146176 },
  { lng: 80.290812, lat: 41.146095 },
  { lng: 80.29056, lat: 41.146054 },
  { lng: 80.290408, lat: 41.146 },
  { lng: 80.290174, lat: 41.145946 },
  { lng: 80.289761, lat: 41.145925 },
  { lng: 80.289599, lat: 41.145966 },
  { lng: 80.289536, lat: 41.146041 },
  { lng: 80.28933, lat: 41.146129 },
  { lng: 80.289222, lat: 41.146224 },
  { lng: 80.288979, lat: 41.146346 },
  { lng: 80.288898, lat: 41.146435 },
  { lng: 80.288728, lat: 41.146496 },
  { lng: 80.288557, lat: 41.146584 },
  { lng: 80.288306, lat: 41.146727 },
  { lng: 80.288126, lat: 41.146862 },
  { lng: 80.287937, lat: 41.146971 },
  { lng: 80.28774, lat: 41.147066 },
  { lng: 80.287641, lat: 41.147134 },
  { lng: 80.287479, lat: 41.147202 },
  { lng: 80.28729, lat: 41.147277 },
  { lng: 80.287219, lat: 41.147392 },
  { lng: 80.287066, lat: 41.147467 },
  { lng: 80.286868, lat: 41.147609 },
  { lng: 80.286716, lat: 41.147732 },
  { lng: 80.286509, lat: 41.147799 },
  { lng: 80.286383, lat: 41.147949 },
  { lng: 80.286212, lat: 41.147976 },
  { lng: 80.285952, lat: 41.148234 },
  { lng: 80.285754, lat: 41.148356 },
  { lng: 80.285548, lat: 41.148526 },
  { lng: 80.28535, lat: 41.148675 },
  { lng: 80.28517, lat: 41.148804 },
  { lng: 80.284991, lat: 41.148933 },
  { lng: 80.28464, lat: 41.149266 },
  { lng: 80.284542, lat: 41.149456 },
  { lng: 80.284326, lat: 41.149612 },
  { lng: 80.284191, lat: 41.149823 },
  { lng: 80.284048, lat: 41.149993 },
  { lng: 80.283859, lat: 41.150224 },
  { lng: 80.283751, lat: 41.150407 },
  { lng: 80.283571, lat: 41.150611 },
  { lng: 80.283527, lat: 41.15074 },
  { lng: 80.283428, lat: 41.150916 },
  { lng: 80.283356, lat: 41.151066 },
  { lng: 80.28323, lat: 41.151181 },
  { lng: 80.28314, lat: 41.151378 },
  { lng: 80.283006, lat: 41.151527 },
  { lng: 80.282871, lat: 41.151799 },
  { lng: 80.282799, lat: 41.151962 },
  { lng: 80.282709, lat: 41.152091 },
  { lng: 80.282601, lat: 41.152227 },
  { lng: 80.28252, lat: 41.152369 },
  { lng: 80.282314, lat: 41.152695 },
  { lng: 80.282215, lat: 41.152851 },
  { lng: 80.282215, lat: 41.15296 },
  { lng: 80.28208, lat: 41.153096 },
  { lng: 80.281928, lat: 41.1533 },
  { lng: 80.28191, lat: 41.153429 },
  { lng: 80.281694, lat: 41.153714 },
  { lng: 80.281559, lat: 41.153958 },
  { lng: 80.281487, lat: 41.154128 },
  { lng: 80.281344, lat: 41.154311 },
  { lng: 80.281209, lat: 41.154624 },
  { lng: 80.28111, lat: 41.154786 },
  { lng: 80.281038, lat: 41.154943 },
  { lng: 80.280895, lat: 41.155119 },
  { lng: 80.280841, lat: 41.155282 },
  { lng: 80.280715, lat: 41.155438 },
  { lng: 80.280625, lat: 41.155594 },
  { lng: 80.280508, lat: 41.155812 },
  { lng: 80.28049, lat: 41.155948 },
  { lng: 80.280409, lat: 41.15609 },
  { lng: 80.280338, lat: 41.156233 },
  { lng: 80.280221, lat: 41.156409 },
  { lng: 80.28014, lat: 41.156525 },
  { lng: 80.28014, lat: 41.15664 },
  { lng: 80.279987, lat: 41.156606 },
  { lng: 80.279457, lat: 41.156443 },
  { lng: 80.279152, lat: 41.156402 },
  { lng: 80.278837, lat: 41.156267 },
  { lng: 80.278613, lat: 41.156239 },
  { lng: 80.278164, lat: 41.156144 },
  { lng: 80.277652, lat: 41.155981 },
  { lng: 80.277391, lat: 41.15592 },
  { lng: 80.277077, lat: 41.155825 },
  { lng: 80.276771, lat: 41.155798 },
  { lng: 80.276664, lat: 41.155737 },
  { lng: 80.276529, lat: 41.155676 },
  { lng: 80.276026, lat: 41.155561 },
  { lng: 80.275819, lat: 41.15552 },
  { lng: 80.275172, lat: 41.155336 },
  { lng: 80.274849, lat: 41.155282 },
  { lng: 80.274454, lat: 41.155153 },
  { lng: 80.27422, lat: 41.155106 },
  { lng: 80.273897, lat: 41.154997 },
  { lng: 80.273196, lat: 41.154807 },
  { lng: 80.2729, lat: 41.154698 },
  { lng: 80.272504, lat: 41.154562 },
  { lng: 80.272244, lat: 41.154474 },
  { lng: 80.271822, lat: 41.154359 },
  { lng: 80.271543, lat: 41.154277 },
  { lng: 80.271202, lat: 41.154196 },
  { lng: 80.270842, lat: 41.154094 },
  { lng: 80.270636, lat: 41.15406 },
  { lng: 80.270304, lat: 41.153965 },
  { lng: 80.26998, lat: 41.15387 },
  { lng: 80.269756, lat: 41.153788 },
  { lng: 80.269279, lat: 41.153687 },
  { lng: 80.268911, lat: 41.153537 },
  { lng: 80.26866, lat: 41.153456 },
  { lng: 80.268246, lat: 41.153361 },
  { lng: 80.267591, lat: 41.153143 },
  { lng: 80.267339, lat: 41.153075 },
  { lng: 80.267034, lat: 41.152987 },
  { lng: 80.266719, lat: 41.152892 },
  { lng: 80.266522, lat: 41.152838 },
  { lng: 80.266261, lat: 41.152783 },
  { lng: 80.265902, lat: 41.152654 },
  { lng: 80.265632, lat: 41.152593 },
  { lng: 80.265327, lat: 41.152519 },
  { lng: 80.265004, lat: 41.152403 },
  { lng: 80.264761, lat: 41.152267 },
  { lng: 80.264491, lat: 41.152145 },
  { lng: 80.264303, lat: 41.152077 },
  { lng: 80.264015, lat: 41.152016 },
  { lng: 80.263719, lat: 41.151867 },
  { lng: 80.263315, lat: 41.152342 },
  { lng: 80.263126, lat: 41.152654 },
  { lng: 80.262919, lat: 41.152967 },
  { lng: 80.262785, lat: 41.153075 },
  { lng: 80.262731, lat: 41.153184 },
  { lng: 80.262641, lat: 41.153374 },
  { lng: 80.262569, lat: 41.153462 },
  { lng: 80.262479, lat: 41.153625 },
  { lng: 80.262192, lat: 41.153985 },
  { lng: 80.262102, lat: 41.154189 },
  { lng: 80.261913, lat: 41.154528 },
  { lng: 80.261761, lat: 41.154698 },
  { lng: 80.261671, lat: 41.154861 },
  { lng: 80.261608, lat: 41.15499 },
  { lng: 80.2615, lat: 41.155106 },
  { lng: 80.261464, lat: 41.155207 },
  { lng: 80.261302, lat: 41.155432 },
  { lng: 80.261168, lat: 41.15552 },
  { lng: 80.26106, lat: 41.155778 },
  { lng: 80.260979, lat: 41.155934 },
  { lng: 80.260862, lat: 41.156097 },
  { lng: 80.260746, lat: 41.156233 },
  { lng: 80.260584, lat: 41.156565 },
  { lng: 80.260476, lat: 41.156654 },
  { lng: 80.260377, lat: 41.156851 },
  { lng: 80.260341, lat: 41.156993 },
  { lng: 80.260251, lat: 41.157054 },
  { lng: 80.260153, lat: 41.15717 },
  { lng: 80.260036, lat: 41.157333 },
  { lng: 80.259928, lat: 41.157509 },
  { lng: 80.25991, lat: 41.157611 },
  { lng: 80.259811, lat: 41.157733 },
  { lng: 80.25973, lat: 41.157821 },
  { lng: 80.259641, lat: 41.158005 },
  { lng: 80.259542, lat: 41.1581 },
  { lng: 80.259488, lat: 41.158195 },
  { lng: 80.259425, lat: 41.158324 },
  { lng: 80.25938, lat: 41.158412 },
  { lng: 80.259317, lat: 41.158541 },
  { lng: 80.259245, lat: 41.15865 },
  { lng: 80.259003, lat: 41.158704 },
  { lng: 80.258832, lat: 41.158786 },
  { lng: 80.258688, lat: 41.158813 },
  { lng: 80.258536, lat: 41.158867 },
  { lng: 80.258392, lat: 41.158915 },
  { lng: 80.258239, lat: 41.158928 },
  { lng: 80.257826, lat: 41.158928 },
  { lng: 80.257655, lat: 41.158928 },
  { lng: 80.257215, lat: 41.159003 },
  { lng: 80.257009, lat: 41.159016 },
  { lng: 80.256452, lat: 41.159044 },
  { lng: 80.256164, lat: 41.159084 },
  { lng: 80.255086, lat: 41.159166 },
  { lng: 80.254682, lat: 41.159213 },
  { lng: 80.254457, lat: 41.159227 },
  { lng: 80.254197, lat: 41.159261 },
  { lng: 80.253882, lat: 41.159261 },
  { lng: 80.254134, lat: 41.159872 },
  { lng: 80.254395, lat: 41.160293 },
  { lng: 80.254709, lat: 41.160931 },
  { lng: 80.254942, lat: 41.161379 },
  { lng: 80.255122, lat: 41.162723 },
  { lng: 80.255194, lat: 41.162988 },
  { lng: 80.255221, lat: 41.163117 },
  { lng: 80.255158, lat: 41.163531 },
  { lng: 80.255149, lat: 41.163701 },
  { lng: 80.255095, lat: 41.164013 },
  { lng: 80.255131, lat: 41.164251 },
  { lng: 80.255176, lat: 41.16531 },
  { lng: 80.255131, lat: 41.16571 },
  { lng: 80.255194, lat: 41.16586 },
  { lng: 80.25514, lat: 41.166104 },
  { lng: 80.255131, lat: 41.166376 },
  { lng: 80.255158, lat: 41.166735 },
  { lng: 80.255158, lat: 41.166912 },
  { lng: 80.255149, lat: 41.167129 },
  { lng: 80.255185, lat: 41.167319 },
  { lng: 80.255149, lat: 41.167489 },
  { lng: 80.255095, lat: 41.167665 },
  { lng: 80.255077, lat: 41.167869 },
  { lng: 80.255068, lat: 41.168018 },
  { lng: 80.255068, lat: 41.168141 },
  { lng: 80.255068, lat: 41.168256 },
  { lng: 80.255104, lat: 41.168392 },
  { lng: 80.255203, lat: 41.168738 },
  { lng: 80.255203, lat: 41.168833 },
  { lng: 80.25514, lat: 41.169288 },
  { lng: 80.255149, lat: 41.169464 },
  { lng: 80.254925, lat: 41.170041 },
  { lng: 80.2547, lat: 41.170415 },
  { lng: 80.254592, lat: 41.170605 },
  { lng: 80.254529, lat: 41.170808 },
  { lng: 80.254403, lat: 41.171053 },
  { lng: 80.254053, lat: 41.171833 },
  { lng: 80.253981, lat: 41.17203 },
  { lng: 80.253873, lat: 41.172207 },
  { lng: 80.253775, lat: 41.172404 },
  { lng: 80.25355, lat: 41.172852 },
  { lng: 80.253505, lat: 41.172981 },
  { lng: 80.253496, lat: 41.17313 },
  { lng: 80.253272, lat: 41.17334 },
  { lng: 80.253209, lat: 41.173503 },
  { lng: 80.25293, lat: 41.17372 },
  { lng: 80.252778, lat: 41.173931 },
  { lng: 80.25258, lat: 41.174162 },
  { lng: 80.252445, lat: 41.174352 },
  { lng: 80.252095, lat: 41.174664 },
  { lng: 80.251951, lat: 41.174935 },
  { lng: 80.25178, lat: 41.175017 },
  { lng: 80.251691, lat: 41.175193 },
  { lng: 80.251565, lat: 41.175316 },
  { lng: 80.251439, lat: 41.175465 },
  { lng: 80.251286, lat: 41.175628 },
  { lng: 80.25108, lat: 41.175804 },
  { lng: 80.250873, lat: 41.17611 },
  { lng: 80.250738, lat: 41.176273 },
  { lng: 80.250262, lat: 41.176659 },
  { lng: 80.250128, lat: 41.176822 },
  { lng: 80.249858, lat: 41.177196 },
  { lng: 80.249705, lat: 41.177379 },
  { lng: 80.249445, lat: 41.177617 },
  { lng: 80.249319, lat: 41.177752 },
  { lng: 80.249184, lat: 41.177942 },
  { lng: 80.249148, lat: 41.178078 },
  { lng: 80.248969, lat: 41.178146 },
  { lng: 80.248735, lat: 41.178492 },
  { lng: 80.248573, lat: 41.178682 },
  { lng: 80.248421, lat: 41.178818 },
  { lng: 80.248214, lat: 41.178974 },
  { lng: 80.248052, lat: 41.179212 },
  { lng: 80.247837, lat: 41.179402 },
  { lng: 80.247756, lat: 41.179592 },
  { lng: 80.251017, lat: 41.180325 },
  { lng: 80.251358, lat: 41.180426 },
  { lng: 80.251646, lat: 41.180535 },
  { lng: 80.252373, lat: 41.180718 },
  { lng: 80.252607, lat: 41.1808 },
  { lng: 80.252787, lat: 41.18084 },
  { lng: 80.253326, lat: 41.181064 },
  { lng: 80.253712, lat: 41.181227 },
  { lng: 80.253936, lat: 41.181363 },
  { lng: 80.25443, lat: 41.181614 },
  { lng: 80.254871, lat: 41.181886 },
  { lng: 80.255095, lat: 41.182021 },
  { lng: 80.255257, lat: 41.182123 },
  { lng: 80.255401, lat: 41.182211 },
  { lng: 80.255544, lat: 41.182293 },
  { lng: 80.255724, lat: 41.182381 },
  { lng: 80.256101, lat: 41.182551 },
  { lng: 80.256389, lat: 41.18268 },
  { lng: 80.256649, lat: 41.182768 },
  { lng: 80.257368, lat: 41.183012 },
  { lng: 80.257673, lat: 41.183162 },
  { lng: 80.258096, lat: 41.183304 },
  { lng: 80.258715, lat: 41.183528 },
  { lng: 80.259407, lat: 41.183732 },
  { lng: 80.25965, lat: 41.183833 },
  { lng: 80.26, lat: 41.183962 },
  { lng: 80.260413, lat: 41.184112 },
  { lng: 80.260575, lat: 41.184173 },
  { lng: 80.260728, lat: 41.184234 },
  { lng: 80.260943, lat: 41.184322 },
  { lng: 80.261078, lat: 41.18437 },
  { lng: 80.261249, lat: 41.184071 },
  { lng: 80.261374, lat: 41.183928 },
  { lng: 80.261527, lat: 41.183827 },
  { lng: 80.261653, lat: 41.183671 },
  { lng: 80.261904, lat: 41.183399 },
  { lng: 80.26221, lat: 41.183094 },
  { lng: 80.262327, lat: 41.182972 },
  { lng: 80.262605, lat: 41.182673 },
  { lng: 80.262803, lat: 41.18251 },
  { lng: 80.263009, lat: 41.182191 },
  { lng: 80.263171, lat: 41.182035 },
  { lng: 80.263252, lat: 41.181947 },
  { lng: 80.263378, lat: 41.181804 },
  { lng: 80.263764, lat: 41.18139 },
  { lng: 80.263961, lat: 41.181153 },
  { lng: 80.264123, lat: 41.180963 },
  { lng: 80.264303, lat: 41.180766 },
  { lng: 80.264456, lat: 41.180644 },
  { lng: 80.264608, lat: 41.180508 },
  { lng: 80.264716, lat: 41.180297 },
  { lng: 80.265165, lat: 41.179829 },
  { lng: 80.265309, lat: 41.179639 },
  { lng: 80.265435, lat: 41.17949 },
  { lng: 80.265677, lat: 41.179218 },
  { lng: 80.265848, lat: 41.179042 },
  { lng: 80.266234, lat: 41.178635 },
  { lng: 80.266351, lat: 41.178506 },
  { lng: 80.266396, lat: 41.178322 },
  { lng: 80.266809, lat: 41.177915 },
  { lng: 80.26724, lat: 41.177352 },
  { lng: 80.267366, lat: 41.177223 },
  { lng: 80.267519, lat: 41.176985 },
  { lng: 80.267725, lat: 41.176836 },
  { lng: 80.267977, lat: 41.176564 },
  { lng: 80.268255, lat: 41.176184 },
  { lng: 80.268417, lat: 41.176028 },
  { lng: 80.268597, lat: 41.175899 },
  { lng: 80.268875, lat: 41.175451 },
  { lng: 80.26901, lat: 41.175248 },
  { lng: 80.269154, lat: 41.175125 },
  { lng: 80.269675, lat: 41.174487 },
  { lng: 80.269836, lat: 41.174311 },
  { lng: 80.269917, lat: 41.174155 },
  { lng: 80.270133, lat: 41.173782 },
  { lng: 80.270259, lat: 41.173653 },
  { lng: 80.270366, lat: 41.173517 },
  { lng: 80.270564, lat: 41.173374 },
  { lng: 80.270842, lat: 41.173015 },
  { lng: 80.270995, lat: 41.172852 },
  { lng: 80.271094, lat: 41.172695 },
  { lng: 80.271193, lat: 41.172519 },
  { lng: 80.271606, lat: 41.171996 },
  { lng: 80.271696, lat: 41.171772 },
  { lng: 80.271768, lat: 41.171623 },
  { lng: 80.271902, lat: 41.171508 },
  { lng: 80.271938, lat: 41.171338 },
  { lng: 80.271965, lat: 41.171148 },
  { lng: 80.272172, lat: 41.170788 },
  { lng: 80.272334, lat: 41.170435 },
  { lng: 80.272415, lat: 41.170177 },
  { lng: 80.272603, lat: 41.169906 },
  { lng: 80.272612, lat: 41.16979 },
  { lng: 80.2729, lat: 41.169315 },
  { lng: 80.273097, lat: 41.168914 },
  { lng: 80.273151, lat: 41.168724 },
  { lng: 80.273187, lat: 41.168623 },
  { lng: 80.273187, lat: 41.168507 },
  { lng: 80.273259, lat: 41.168331 },
  { lng: 80.273358, lat: 41.168181 },
  { lng: 80.273519, lat: 41.167869 },
  { lng: 80.273735, lat: 41.167489 },
  { lng: 80.273906, lat: 41.167272 },
  { lng: 80.274014, lat: 41.167075 },
  { lng: 80.274085, lat: 41.166925 },
  { lng: 80.274238, lat: 41.166586 },
  { lng: 80.274373, lat: 41.166403 },
  { lng: 80.274463, lat: 41.166247 },
  { lng: 80.274615, lat: 41.165928 },
  { lng: 80.274768, lat: 41.165676 },
  { lng: 80.274858, lat: 41.165547 },
  { lng: 80.27493, lat: 41.165418 },
  { lng: 80.275253, lat: 41.164889 },
  { lng: 80.275343, lat: 41.164733 },
  { lng: 80.275451, lat: 41.164563 },
  { lng: 80.27572, lat: 41.163986 },
  { lng: 80.275819, lat: 41.163789 },
  { lng: 80.275945, lat: 41.16364 },
  { lng: 80.27608, lat: 41.16345 },
  { lng: 80.276178, lat: 41.163321 },
  { lng: 80.276295, lat: 41.163212 },
  { lng: 80.276493, lat: 41.162791 },
  { lng: 80.276601, lat: 41.16252 },
  { lng: 80.276735, lat: 41.162309 },
  { lng: 80.276843, lat: 41.162099 },
  { lng: 80.276969, lat: 41.161881 },
  { lng: 80.277202, lat: 41.161589 },
  { lng: 80.277418, lat: 41.16123 },
  { lng: 80.277517, lat: 41.161046 },
  { lng: 80.277598, lat: 41.160836 },
  { lng: 80.277759, lat: 41.160659 },
  { lng: 80.277867, lat: 41.160476 },
  { lng: 80.278074, lat: 41.160021 },
  { lng: 80.278254, lat: 41.15977 },
  { lng: 80.278271, lat: 41.159634 },
  { lng: 80.27855, lat: 41.159227 },
  { lng: 80.278595, lat: 41.159111 },
  { lng: 80.278757, lat: 41.158894 },
  { lng: 80.279314, lat: 41.158012 },
  { lng: 80.279448, lat: 41.157754 },
  { lng: 80.279556, lat: 41.157577 },
  { lng: 80.279942, lat: 41.157 },
  { lng: 80.280059, lat: 41.15681 },
  { lng: 80.280194, lat: 41.156606 },
  { lng: 80.280176, lat: 41.156497 },
  { lng: 80.280257, lat: 41.156328 },
  { lng: 80.28093, lat: 41.155146 },
  { lng: 80.280993, lat: 41.154997 },
  { lng: 80.281074, lat: 41.154793 },
  { lng: 80.281272, lat: 41.154549 },
  { lng: 80.281344, lat: 41.154311 },
  { lng: 80.281559, lat: 41.154019 },
  { lng: 80.281667, lat: 41.153822 },
  { lng: 80.281757, lat: 41.153625 },
  { lng: 80.281946, lat: 41.153462 },
  { lng: 80.282161, lat: 41.152926 },
  { lng: 80.282269, lat: 41.152756 },
  { lng: 80.282413, lat: 41.152546 },
  { lng: 80.28279, lat: 41.151874 },
  { lng: 80.282988, lat: 41.15148 },
  { lng: 80.283086, lat: 41.151303 },
  { lng: 80.283482, lat: 41.150855 },
  { lng: 80.283868, lat: 41.150237 },
  { lng: 80.284003, lat: 41.150074 },
  { lng: 80.284254, lat: 41.149708 },
  { lng: 80.28438, lat: 41.149572 },
  { lng: 80.284982, lat: 41.149022 },
  { lng: 80.285108, lat: 41.148886 },
  { lng: 80.285332, lat: 41.148716 },
  { lng: 80.285485, lat: 41.148607 },
  { lng: 80.285665, lat: 41.148485 },
  { lng: 80.28579, lat: 41.148322 },
  { lng: 80.285943, lat: 41.14818 },
  { lng: 80.286527, lat: 41.14782 },
  { lng: 80.286814, lat: 41.147616 },
  { lng: 80.287003, lat: 41.147528 },
  { lng: 80.287174, lat: 41.147378 },
  { lng: 80.287542, lat: 41.147188 },
  { lng: 80.287847, lat: 41.147012 },
  { lng: 80.288036, lat: 41.146923 },
  { lng: 80.288458, lat: 41.146713 },
  { lng: 80.288683, lat: 41.14657 },
  { lng: 80.288961, lat: 41.146428 },
  { lng: 80.289123, lat: 41.146326 },
  { lng: 80.289375, lat: 41.146156 },
  { lng: 80.289635, lat: 41.146054 },
  { lng: 80.289833, lat: 41.145918 },
  { lng: 80.290075, lat: 41.145905 },
  { lng: 80.290264, lat: 41.145946 },
  { lng: 80.290506, lat: 41.146027 },
  { lng: 80.290911, lat: 41.146163 },
  { lng: 80.291665, lat: 41.14636 },
  { lng: 80.292168, lat: 41.146455 },
  { lng: 80.292384, lat: 41.146502 },
  { lng: 80.292537, lat: 41.146577 },
  { lng: 80.292707, lat: 41.146665 },
  { lng: 80.292914, lat: 41.146686 }
]
// 生成轨迹数据
//
export const generateVehicleTrackData = (tmpArr, s1, s2, defStartTime, defEndTime) => {
  // 约定时间范围
  const startTime = moment().format('YYYY-MM-DD') + ' ' + defStartTime
  const startDateTime = moment(startTime)._d.getTime()
  const endTime = moment().format('YYYY-MM-DD') + ' ' + defEndTime
  const endDateTime = moment(endTime)._d.getTime()
  // 获取时间分隔值
  const splitTime = tmpArr.length > 0 ? Math.floor((endDateTime - startDateTime) / tmpArr.length) : 0

  // 实际查询时间
  const s1Time = moment().format('YYYY-MM-DD') + ' ' + s1
  const s1DateTime = moment(s1Time)._d.getTime()

  const s2Time = moment().format('YYYY-MM-DD') + ' ' + s2
  const s2DateTime = moment(s2Time)._d.getTime()

  // 小于开始时间或开始时间超过
  if (s1 > defEndTime) {
    return []
  }
  let newArr = [...tmpArr]

  if (s1 <= defStartTime && s2 >= defEndTime) {
    // 起止时间落在范围内
    return generateTrackData(newArr, defStartTime, defEndTime)
  } else if (s1 > defStartTime && s2 < defEndTime) {
    // 落在中间位置
    newArr = []
    let k = startDateTime
    let i = 0
    while (k < s2DateTime) {
      if (k >= s1DateTime) {
        if (i < tmpArr.length) {
          newArr.push(tmpArr[i])
        }
      }
      i++
      k += splitTime
    }
    return generateTrackData(newArr, s1, s2)
  } else if (s1 > defStartTime) {
    // 超过开始到结束
    newArr = []
    let k = startDateTime
    let i = 0
    while (k <= endDateTime) {
      if (k >= s1DateTime) {
        if (i < tmpArr.length) {
          newArr.push(tmpArr[i])
        }
      }
      i++
      k += splitTime
    }
    return generateTrackData(newArr, s1, defEndTime)
  } else if (s2 < defEndTime) {
    // 开始未到结束
    newArr = []
    let k = startDateTime
    let i = 0
    while (k <= s2DateTime) {
      if (i < tmpArr.length) {
        newArr.push(tmpArr[i])
      }
      i++
      k += splitTime
    }
    return generateTrackData(newArr, defStartTime, s2)
  }
  return []
}

// 01,02,03三辆车轨迹数据是 oneData
// 04,05,06三辆车轨迹数据是 twoData
export const getVehicleTrackData = params => {
  let startTime = params.startTime.substr(11, 5)
  let endTime = params.endTime.substr(11, 5)
  // 如果查询是当天的数据，那么结束时间不能大于当前时间，开始时间也不能大于当前时间
  const startDate = params.startTime.substr(0, 10)
  const endDate = params.endTime.substr(0, 10)
  const currentDate = moment().format('YYYY-MM-DD')
  if (startDate > currentDate) {
    // 开始日期大于今天
    return []
  }
  if (startDate === currentDate) {
    // 查询当天的
    const currentTime = moment().format('HH:mm')
    if (startTime > currentTime) {
      startTime = currentTime
    }
    if (endTime > currentTime) {
      endTime = currentTime
    }
  } else if (startDate < currentDate && startDate < endDate) {
    // 查询昨天到今天的数据,修正查询到昨天19：00
    endTime = '19:00'
  }
  let oneData = []
  let twoData = []
  // 中间时段没有轨迹
  if (startTime > '10:30' && endTime < '16:00') {
    return []
  } else if (endTime < '16:00') {
    // 上午路线1
    oneData = generateVehicleTrackData([...oneTrackData], startTime, endTime, '07:00', '10:30')
    // 上午路线2
    twoData = generateVehicleTrackData([...twoTrackData], startTime, endTime, '07:00', '10:30')
  } else if (startTime > '10:30') {
    // 下午路线1
    oneData = generateVehicleTrackData([...oneTrackData], startTime, endTime, '16:00', '19:00')
    // 下午路线2
    twoData = generateVehicleTrackData([...twoTrackData], startTime, endTime, '16:00', '19:00')
  } else if (startTime < '10:30' && endTime > '16:00') {
    // 包含上午与下午路线1
    const oneData1 = generateVehicleTrackData([...oneTrackData], startTime, '10:30', '07:00', '10:30')
    const oneData2 = generateVehicleTrackData([...oneTrackData], '16:00', endTime, '16:00', '19:00')
    oneData = [...oneData1, ...oneData2]

    const twoData1 = generateVehicleTrackData([...twoTrackData], startTime, '10:30', '07:00', '10:30')
    const twoData2 = generateVehicleTrackData([...twoTrackData], '16:00', endTime, '16:00', '19:00')
    twoData = [...twoData1, ...twoData2]
  }

  if (startDate < currentDate) {
    // 改轨迹日期
    oneData.forEach(p => {
      p.gpsTime = startDate + p.gpsTime.substring(10)
    })
    twoData.forEach(p => {
      p.gpsTime = startDate + p.gpsTime.substring(10)
    })
  }
  return {
    1: oneData,
    2: twoData
  }
}
// 地图对象，上一次的坐标，当前坐标， 目标坐标
const getRotation = (map, curPos, targetPos) => {
  var deg = 0
  curPos = map.pointToPixel(curPos)
  targetPos = map.pointToPixel(targetPos)

  if (targetPos.x !== curPos.x) {
    var tan = (targetPos.y - curPos.y) / (targetPos.x - curPos.x)
    var atan = Math.atan(tan)
    deg = (atan * 360) / (2 * Math.PI)
    if (targetPos.x < curPos.x) {
      deg = -deg + 90 + 90
    } else {
      deg = -deg
    }
    return deg
  } else {
    var disy = targetPos.y - curPos.y
    var bias = 0
    if (disy > 0) bias = -1
    else bias = 1
    return -bias * 90
  }
}

// 获取当前位置
export const getCurrentLocation = (map, flag, minutes) => {
  let trackData = []
  let defStartTime = ''
  let defEndTime = ''
  // 当前时间
  if ('123'.indexOf(flag) >= 0) {
    trackData = [...oneTrackData]
  } else {
    // 345
    trackData = [...twoTrackData]
  }
  const t = moment().format('HH:mm')
  if (t >= '07:00' && t <= '10:30') {
    defStartTime = '07:00'
    defEndTime = '10:30'
  }
  if (t >= '16:00' && t <= '19:00') {
    defStartTime = '16:00'
    defEndTime = '19:00'
  }
  const currentTime = new Date().getTime() + minutes * 60 * 1000

  // 约定时间范围
  const startTime = moment().format('YYYY-MM-DD') + ' ' + defStartTime
  const startDateTime = moment(startTime)._d.getTime()
  const endTime = moment().format('YYYY-MM-DD') + ' ' + defEndTime
  const endDateTime = moment(endTime)._d.getTime()

  // 获取时间分隔值
  const splitTime = trackData.length > 0 ? Math.floor((endDateTime - startDateTime) / trackData.length) : 0

  let k = startDateTime
  let i = 0
  const tmpArr = []
  while (k <= endDateTime) {
    if (k >= currentTime) {
      if (i < trackData.length) {
        tmpArr.push(trackData[i])
        // 只要取2个坐标点
        if (tmpArr.length === 2) {
          break
        }
      }
    }
    i++
    k += splitTime
  }
  if (tmpArr.length === 0) {
    tmpArr.push(trackData[trackData.length - 2])
    tmpArr.push(trackData[trackData.length - 1])
  } else if (tmpArr.length === 1) {
    tmpArr.push(trackData[trackData.length - 2])
  }
  const rotation = getRotation(map, tmpArr[0], tmpArr[1])
  // debugger
  const currentLocation = { ...tmpArr[0] }
  currentLocation.rotation = -rotation
  return currentLocation
}
